import React from "react";
import "../../css/footer-header/footer.css";

export const Footer = () => {
  return <div className="footer ">
    <div className="line"> </div>
    <div className="push"></div>
    <a>Made by Greenblob, contact me on discord (_greenblob) with any issues you may have</a>
    <div className="push"></div>
  </div>;
};
